import {
  put,
  all,
  call,
  fork,
  select,
  takeEvery,
  takeLatest,
  allSettled,
} from "redux-saga/effects";
import ActionTypes from "../constants/actionTypes";
import * as actions from "../actions/actions";
import * as selectors from "../selectors/selectors";
import { sendRestRequest } from "../api/requestSender";
import urls from "../constants/urls";
import { parseJSONByDB } from "../helpers/JSON";
import * as modelLogic from "./logic";
import newStrings from "./strings/newStrings";
import xz11 from "./strings/xz";
import { getHeaderTableList } from "../modules/rootComponent/components/table/components/headerTable/logics";
import { feeConstantData } from "../constants/fee";
import { getFeeFilteredList, getFeeFormattedList } from "./logic";
let prevSubModule = "";

export default function* watchModelActions() {
  yield call(getMeRequest);
  yield takeEvery(ActionTypes.INIT_DATA, initData);
  yield takeEvery(ActionTypes.CHANGE_TABLE_MODE, changeTableMode);
  yield takeEvery(ActionTypes.CHANGE_TABLE_SUB_MODE, changeTableSubMode);
  yield takeEvery(ActionTypes.ON_CONFIRM_ACTION, onConfirmAction);
  yield takeLatest(
    ActionTypes.SET_STRINGS_SEARCH_INPUT_VALUE,
    setStringsSearchInputValue,
  );
  yield takeEvery(
    ActionTypes.SET_IS_EMPTY_STRINGS_FILTER_MODE,
    setIsEmptyStringsFilterMode,
  );
  yield takeEvery(
    ActionTypes.SET_GEO_DATA_SELECTED_PAGE,
    setGeoDataSelectedPage,
  );
}

export function* initData() {
  const tableMode = yield select(selectors.getTableItemsMode);

  yield call(initPlatformLanguages);
  yield call(changeTableMode, { payload: tableMode });
}

export function* getMeRequest() {
  const token = localStorage.getItem("uat");

  if (!token) {
    return false;
  }

  const { me, headers } = urls;

  const requestConfig = {
    url: me,
    method: "get",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
    withCredentials: true,
  };

  const meResponse = yield call(sendRestRequest, requestConfig);
  const userData = yield call(parseJSONByDB, meResponse);

  if (
    meResponse?.status === 200 &&
    Object.keys(meResponse?.data?.profile)?.length
  ) {
    yield put(
      actions.sendUserInfoResponseStore({
        token,
        userType: userData.userType,
      }),
    );
    return true;
  } else {
    localStorage.removeItem("uat");
    // yield fork(logOut);
    return false;
  }
}

export function* initPlatformLanguages() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }

  const { headers, languagesList } = urls;
  const requestConfig = {
    url: languagesList,
    method: "get",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
  };

  const languagesResponse = yield call(sendRestRequest, requestConfig);
  const parsedLanguagesResponse = yield call(parseJSONByDB, languagesResponse);

  if (parsedLanguagesResponse.status === "Success") {
    const languagesData = yield select(selectors.getPlatformLanguagesData);
    const languages = {
      languagesUI: [
        ...languagesData.headerDefaultItems,
        ...parsedLanguagesResponse.data.map((item) => item.title),
      ],
      languagesData: [...parsedLanguagesResponse.data],
    };

    yield put(actions.setPlatformLanguagesStore({ ...languages }));
  }
}

export function* onConfirmAction(action) {
  const modalContext = yield select(selectors.getModalContext);

  switch (modalContext.subType) {
    case "addLanguage":
      yield fork(sendAddLanguageRequest);

      break;
    case "deletePhrase":
      yield fork(deletePhrase);

      break;
    case "addNewPhrase":
      yield fork(sendAddPhraseRequest);
      break;
    case "changeStringValue":
      yield fork(sendChangedStringValueRequest, action.payload);
      break;
    default:
      break;
  }

  yield fork(initData);
  yield put(actions.onCloseModalWindow());
}

export function* sendAddLanguageRequest() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }

  const { code, title } = yield select(selectors.getAddLanguageInputsValue);
  const { headers, addLanguage } = urls;
  const requestConfig = {
    url: `${addLanguage}?code=${code}&title=${title}`,
    method: "post",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
  };

  yield call(sendRestRequest, requestConfig);
}

export function* deletePhrase() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }

  const modalContext = yield select(selectors.getModalContext);
  const phrases = yield select(selectors.getPhrasesData);
  const phraseId = phrases.filter(
    (item) => item.text === modalContext.text,
  )?.[0]?.id;

  if (!phraseId) {
    return false;
  }

  const { headers, deletePhrase } = urls;
  const requestConfig = {
    url: `${deletePhrase}?phraseId=${phraseId}`,
    method: "post",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
  };

  yield call(sendRestRequest, requestConfig);
}

export function* sendAddPhraseRequest() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }

  const phraseValue = yield select(selectors.getAddParseInputValue);
  const { headers, addPhrase } = urls;
  const requestConfig = {
    url: `${addPhrase}?text=${phraseValue}`,
    method: "post",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
    params: {
      sectionId: 3,
    },
  };

  yield call(sendRestRequest, requestConfig);
}

export function* sendChangedStringValueRequest() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }

  const tableMode = yield select(selectors.getTableItemsMode);
  const { inputValue, selectedKey } = yield select(
    selectors.getChangeTranslateState,
  );

  if (tableMode === "Fee") {
    yield fork(changeFeeConfig, inputValue, selectedKey);

    return;
  }

  const tableSubMode = yield select(selectors.getTableItemsSubMode);
  const splitValue = selectedKey.split(" ");
  const id = splitValue[splitValue.length - 1];
  splitValue.length = splitValue.length - 1;
  const language = splitValue[splitValue.length - 1];
  splitValue.length = splitValue.length - 1;
  const phrase = splitValue.join(" ");
  const phrasesData = yield select(selectors.getPhrasesData);
  const phrasesId = phrasesData.filter((item) => item.text === phrase)?.[0]?.id;
  const { languagesData } = yield select(selectors.getPlatformLanguagesData);
  const languageData = languagesData.filter(
    (item) => item.title === language,
  )?.[0];
  const languageId = languageData?.id;
  const code = languageData?.code;
  const translates = yield select(selectors.getTranslates);
  const translationId = translates?.[code]?.filter(
    (item) => item.phraseId === phrasesId,
  )?.[0]?.translationId;
  const geoPhrasesId = phrasesData?.[id - 1]?.id;
  const geoTranslationId = translates?.[code]?.[id - 1]?.translationId;

  const { headers, createStringValue, changeStringValue } = urls;
  let requestConfig = {
    method: "post",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
  };

  if (language === "VAT") {
    requestConfig = {
      ...requestConfig,
      url: urls.setCountryVAT,
      data: {
        vat: inputValue,
        countryId: phrasesId,
      },
    };
  } else if (!translationId && tableMode !== "Geo") {
    requestConfig = {
      ...requestConfig,
      url: `${createStringValue}`,
      data: {
        phraseId: phrasesId,
        languageId,
        translated: inputValue,
      },
    };
  } else if (!geoTranslationId && tableMode === "Geo") {
    requestConfig = {
      ...requestConfig,
      url: urls[`createStringValue${tableMode}${tableSubMode}`],
      data: {
        value: inputValue,
        langCode: code,
        [`${tableSubMode.toLowerCase()}Id`]: geoPhrasesId,
      },
    };
  } else {
    if (tableMode === "Geo") {
      requestConfig = {
        ...requestConfig,
        url: urls[`changeStringValue${tableMode}${tableSubMode}`],
        data: {
          value: inputValue,
          langCode: code,
          targetId: geoTranslationId,
          [`${tableSubMode.toLowerCase()}Id`]: geoPhrasesId,
        },
      };
    } else {
      requestConfig = {
        ...requestConfig,
        url: `${changeStringValue}`,
        data: {
          targetId: translationId,
          translated: inputValue,
        },
      };
    }
  }

  yield call(sendRestRequest, requestConfig);
  yield put(actions.setTranslateSelectedKey(""));
  yield put(actions.setAddLanguageInputsValue(""));
}

function* changeFeeConfig(inputValue, selectedKey) {
  try {
    const token = yield select(selectors.getUserToken);

    if (!token) {
      return false;
    }

    const [key, currency] = selectedKey?.split(" ");
    const alias = key + "Fee_" + currency.toLowerCase();
    const feeFilteredList = yield select(selectors.getFeeFilteredList);
    const feeData = feeFilteredList.find((el) => el?.alias === alias);

    if (feeData && feeData.id) {
      if (feeData.value !== inputValue) {
        yield fork(changeFeeConfigRequest, Number(inputValue), feeData.id);
      }
    } else {
      yield fork(createFeeConfigRequest, Number(inputValue), alias);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* changeFeeConfigRequest(value, targetId) {
  try {
    const token = yield select(selectors.getUserToken);

    if (!token || !value || !targetId) {
      return false;
    }

    const { headers, editConfigList } = urls;
    const requestConfig = {
      url: editConfigList,
      method: "post",
      headers: {
        ...headers,
        Authorization: `${headers.Authorization}${token}`,
      },
      params: {
        value,
        targetId,
      },
    };

    const changeFeeConfigRequestResponse = yield call(
      sendRestRequest,
      requestConfig,
    );

    return changeFeeConfigRequestResponse?.status === "Success";
  } catch (error) {
    console.error(error);
  }
}

export function* createFeeConfigRequest(value, alias) {
  try {
    const token = yield select(selectors.getUserToken);

    if (!token || !value || !alias) {
      return false;
    }

    const { headers, createConfigList } = urls;
    const requestConfig = {
      url: createConfigList,
      method: "post",
      headers: {
        ...headers,
        Authorization: `${headers.Authorization}${token}`,
      },
      params: {
        value,
        alias,
      },
    };

    const changeFeeConfigRequestResponse = yield call(
      sendRestRequest,
      requestConfig,
    );

    return changeFeeConfigRequestResponse?.status === "Success";
  } catch (error) {
    console.error(error);
  }
}

export function* initPhrasesData(type, search, selectedPage) {
  const token = yield select(selectors.getUserToken);
  const geoDataSelectedPage = yield select(selectors.getGeoDataSelectedPage);
  const page = selectedPage ?? geoDataSelectedPage;

  if (!token) {
    return false;
  }

  const { headers } = urls;
  const requestConfig = {
    url: urls[`phrasesList${type}`],
    method: "get",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
    params:
      type === "Admin"
        ? {
          sectionId: [3],
        }
        : {
          page,
          ...(search ? { search } : {}),
        },
  };

  const phrasesDataResponse = yield call(sendRestRequest, requestConfig);
  const parsedPhrasesDataResponse = yield call(
    parseJSONByDB,
    phrasesDataResponse,
  );

  if (parsedPhrasesDataResponse.status === "Success") {
    let payload;

    if (type.includes("Geo")) {
      const filteredGeoResponseData = yield call(
        modelLogic.getFilteredGeoResponseData,
        [...parsedPhrasesDataResponse.data.data],
        type,
      );
      payload = [...filteredGeoResponseData];
      yield put(
        actions.setGeoDataTotalPageStore(
          parsedPhrasesDataResponse.data.last_page,
        ),
      );
      yield call(getGeoDataTranslation, filteredGeoResponseData);
    } else {
      payload = [...parsedPhrasesDataResponse.data];
    }

    if (selectedPage) {
      yield put(actions.setGeoDataSelectedPage(selectedPage));
    }

    yield put(actions.setAdminPhrasesDataStore(payload));
  }
}

export function* initTranslatesData() {
  const { languagesData } = yield select(selectors.getPlatformLanguagesData);

  for (let language of languagesData) {
    yield call(getTranslatesByLang, language);
  }
}

export function* getGeoDataTranslation(geoList) {
  const { languagesData } = yield select(selectors.getPlatformLanguagesData);
  const payload = yield call(
    modelLogic.getTranslatesByGeoList,
    geoList,
    languagesData,
  );

  yield put(
    actions.setTranslatesDataStore({
      ...payload,
    }),
  );
}

export function* getTranslatesByLang(language) {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }

  const { id, code } = language;
  const { headers, translatesByLang } = urls;
  const requestConfig = {
    url: `${translatesByLang}?languageId=${id}`,
    method: "get",
    headers: {
      ...headers,
      Authorization: `${headers.Authorization}${token}`,
    },
    params: {
      sectionId: [3],
    },
  };

  const translatesData = yield select(selectors.getTranslatesData);
  const translatesDataResponse = yield call(sendRestRequest, requestConfig);
  const parsedTranslatesDataResponse = yield call(
    parseJSONByDB,
    translatesDataResponse,
  );

  if (parsedTranslatesDataResponse.status === "Success") {
    yield put(
      actions.setTranslatesDataStore({
        ...translatesData,
        [code]: [...parsedTranslatesDataResponse.data],
      }),
    );
  }
}

export function* initTableItemsData(type) {
  const { languagesUI, languagesData } = yield select(
    selectors.getPlatformLanguagesData,
  );
  const { tableAddButtonItem } = yield select(selectors.getTableData);
  const phrasesData = yield select(selectors.getPhrasesData);
  const translates = yield select(selectors.getTranslates);
  const geoDataSelectedPage = yield select(selectors.getGeoDataSelectedPage);
  let noParsedItemData = [...phrasesData];
  const isGeoMode = type.includes("Geo");

  if (!isGeoMode) {
    noParsedItemData = [...noParsedItemData, ...tableAddButtonItem];
  }

  const headerTableList = getHeaderTableList(languagesUI, true);
  const parsedItemData = yield call(
    modelLogic.getParsedItemData,
    headerTableList,
    languagesData,
    translates,
    noParsedItemData,
    isGeoMode,
    geoDataSelectedPage,
  );
  yield put(
    actions.setTableItemsStore({
      [`tableItems${type}`]: [...parsedItemData],
    }),
  );
  yield put(actions.setFilteredTableItemsStore([...parsedItemData]));
}

export function* sendAddPhrasesRequest() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }
  // const phrases = Object.keys(newStrings);
  const phrases1 = Object.keys(xz11).reduce((acc1, item1) => {
    const langValue = Object.keys(xz11[item1]).reduce((acc2, item2) => {
      return {
        ...acc2,
        [item2]: item2,
      };
    }, {});

    return {
      ...acc1,
      ...langValue,
    };
  }, {});
  const phrases = Object.keys(phrases1);

  const xz = yield all(
    phrases.map((phraseValue) => {
      const { headers, addPhrase } = urls;
      const requestConfig = {
        url: `${addPhrase}?text=${phraseValue}`,
        method: "post",
        headers: {
          ...headers,
          Authorization: `${headers.Authorization}${token}`,
        },
        params: {
          sectionId: 3,
        },
      };

      return call(sendRestRequest, requestConfig);
    }),
  );

  if (
    xz
      .map((item) => parseJSONByDB(item).status)
      .every((item) => item === "Success")
  ) {
    return "new phrases is complete";
  }
}

export function* sendChangedStringsValuesRequest() {
  const token = yield select(selectors.getUserToken);

  if (!token) {
    return false;
  }
  //const phrases = ['EnjoyingLife','Adrenalin', 'Barbecue', 'BarsWithLiveMusic', 'BusinessIdeas', 'Bike', 'FaithInLove', 'Loyalty', 'Fun', 'FlyInTheClouds', 'Relationships', 'MutualRespect', 'Crush', 'LaughOutLoud', 'Village', 'Confidence', 'Drama', 'Friendship', 'Spirituality', 'Life', 'Magazines', 'StarrySky', 'HealthyLifestyle', 'MeetNewPeople', 'Intelligence', 'InterestingPeople', 'Sincerity', 'Clubs', 'Computers', 'LoveStories', 'Love', 'World', 'AdventureWorld', 'Hope', 'EnjoyingLife', 'RealLove', 'Tenderness', 'NightParties', 'Embrace', 'Communication', 'HelpPeople', 'ReasonForMeeting', 'HumanRights', 'Attachment', 'Simplicity', 'BringHome', 'SimpleJoys', 'ShowEmotions', 'Equality', 'Reflections', 'Zeal', 'RealOutlookOnLife', 'Romance', 'Freedom', 'Sexuality', 'Family', 'SeriousRelationship', 'MakePeopleLaugh', 'Temptation', 'JointDinners', 'Sleep', 'Calmness', 'Passion', 'WarmEmbrace', 'QuietCelebration', 'Creativity', 'Silence', 'Respect', 'RomanticDinner', 'Smiles', 'Appeasement', 'Cosiness', 'Flirt', 'Kiss', 'Honesty', 'SenseOfTact', 'NoisyFeasts', 'Ecology', 'Emotions', 'Values']
  //     const phrases = Object.keys(newStrings);
  const phrases1 = Object.keys(xz11).reduce((acc1, item1) => {
    const langValue = Object.keys(xz11[item1]).reduce((acc2, item2) => {
      return {
        ...acc2,
        [item2]: item2,
      };
    }, {});

    return {
      ...acc1,
      ...langValue,
    };
  }, {});
  const phrases = Object.keys(phrases1);
  const newStrings1 = phrases.reduce((acc3, item3) => {
    return {
      ...acc3,
      [item3]: {
        EN: xz11.EN[item3],
        RU: xz11.RU[item3],
        UA: xz11.UA[item3],
      },
    };
  }, {});

  const { languagesData } = yield select(selectors.getPlatformLanguagesData);
  const phrasesData = yield select(selectors.getPhrasesData);
  const translates = yield select(selectors.getTranslates);

  const xz = yield all(
    phrases.reduce((acc, phrase) => {
      const languages = Object.keys(newStrings1[phrase]);
      const phrasesId = phrasesData.filter((item) => item.text === phrase)?.[0]
        ?.id;
      if (!phrasesData.filter((item) => item.text === phrase)?.[0]?.id) {
        debugger;
      }

      const filteredLang = languages.filter(
        (language) =>
          languagesData.filter((item) => item.code === language).length,
      );

      const requestCallList = filteredLang.map((language) => {
        if (!languagesData.filter((item) => item.code === language)?.[0]?.id) {
          return;
        }
        const inputValue = Array.isArray(newStrings1[phrase][language])
          ? newStrings1[phrase][language].join("{-}")
          : newStrings1[phrase][language];
        const { id: languageId, code } = languagesData.filter(
          (item) => item.code === language,
        )?.[0];
        const translationId = translates?.[code]?.filter(
          (item) => item.phraseId === phrasesId,
        )?.[0]?.translationId;

        if (!phrasesId) {
        }
        const { headers, createStringValue, changeStringValue } = urls;
        let requestConfig = {
          method: "post",
          headers: {
            ...headers,
            Authorization: `${headers.Authorization}${token}`,
          },
          data: {
            translated: inputValue,
          },
        };

        if (!translationId && translationId !== 0) {
          requestConfig = {
            ...requestConfig,
            url: `${createStringValue}?languageId=${languageId}&phraseId=${phrasesId}`,
          };
        } else {
          requestConfig = {
            ...requestConfig,
            url: `${changeStringValue}?targetId=${translationId}`,
          };
        }

        return call(sendRestRequest, requestConfig);
      });

      return [...acc, ...requestCallList];
    }, []),
  );

  if (
    xz
      .map((item) => parseJSONByDB(item).status)
      .every((item) => item === "Success")
  ) {
    return "new translates is complete";
  }
}

export function* changeTableMode(action) {
  if (!action.payload) {
    return false;
  }

  document.body.style.cursor = "wait";
  yield put(actions.setTableModeStore(action.payload));

  if (action.payload === "Geo") {
    const tableSubMode = yield select(selectors.getTableItemsSubMode);

    yield call(changeTableSubMode, { payload: tableSubMode }, true);
  } else if (action.payload === "Fee") {
    yield fork(getFee);
  } else {
    yield call(initPhrasesData, action.payload);
    yield call(initTranslatesData, action.payload);
    yield call(initTableItemsData, action.payload);
    const searchValue = yield select(selectors.getStringsSearchInputValue);
    yield call(setStringsSearchInputValue, { payload: searchValue });
  }

  document.body.style.cursor = "auto";
}

export function* changeTableSubMode(action, isNoNeedCursorWait) {
  if (!action.payload) {
    return false;
  }

  const page =
    prevSubModule !== action.payload
      ? 1
      : yield select(selectors.getGeoDataSelectedPage);

  yield put(actions.setGeoDataSelectedPageStore(page));

  if (!isNoNeedCursorWait) {
    document.body.style.cursor = "wait";
  }

  const tableMode = yield select(selectors.getTableItemsMode);
  const searchValue = yield select(selectors.getStringsSearchInputValue);

  yield call(initPhrasesData, `${tableMode}${action.payload}`, searchValue);
  yield call(initTableItemsData, `${tableMode}${action.payload}`);
  yield put(actions.setTableSubModeStore(action.payload));
  prevSubModule = action.payload;

  if (action.payload !== "City") {
    yield call(setStringsSearchInputValue, { payload: searchValue });
  }

  if (!isNoNeedCursorWait) {
    document.body.style.cursor = "auto";
  }
}

export function* getFee() {
  try {
    const token = localStorage.getItem("uat");

    if (!token) {
      return false;
    }

    const feeList = yield call(getFeRequest, token);
    const feeFilteredList = yield call(
      getFeeFilteredList,
      feeList,
      feeConstantData,
    );
    const feeFormattedList = yield call(getFeeFormattedList, feeFilteredList);

    yield put(actions.setFeeFilteredListStore(feeFilteredList));
    yield put(actions.setFilteredTableItemsStore(feeFormattedList));
  } catch (error) {
    console.error(error);
  }
}

export function* getFeRequest(token) {
  try {
    const { getConfigList, headers } = urls;

    const requestConfig = {
      url: getConfigList,
      method: "get",
      headers: {
        ...headers,
        Authorization: `${headers.Authorization}${token}`,
      },
      withCredentials: true,
    };

    const response = yield call(sendRestRequest, requestConfig);
    let feeList = [];

    if (response?.status === 200 || response?.data?.status === "Success") {
      const responseList = response?.data?.data;
      if (responseList.length) {
        feeList = responseList;
      }
    }

    return feeList;
  } catch (error) {
    console.error(error);
  }
}

export function* setGeoDataSelectedPage(action) {
  if (!action.payload) {
    return false;
  }

  yield put(actions.setGeoDataSelectedPageStore(action.payload));
  document.body.style.cursor = "wait";

  const tableMode = yield select(selectors.getTableItemsMode);
  const tableSubMode = yield select(selectors.getTableItemsSubMode);
  const searchValue = yield select(selectors.getStringsSearchInputValue);

  yield call(initPhrasesData, `${tableMode}${tableSubMode}`, searchValue);
  yield call(initTableItemsData, `${tableMode}${tableSubMode}`);

  if (tableSubMode !== "City") {
    yield call(setStringsSearchInputValue, { payload: searchValue });
  }

  document.body.style.cursor = "auto";
}

export function* setStringsSearchInputValue(action) {
  try {
    yield put(actions.setStringsSearchInputValueStore(action.payload));

    const tableItems = yield select(selectors.getTableItems);
    const isEmptyStringsFilterMode = yield select(
      selectors.getIsEmptyStringsFilterMode,
    );
    const tableMode = yield select(selectors.getTableItemsMode);
    const tableSubMode = yield select(selectors.getTableItemsSubMode);

    if (tableMode === "Geo" && tableSubMode === "City") {
      yield call(
        initPhrasesData,
        `${tableMode}${tableSubMode}`,
        action.payload,
        1,
      );
      yield call(initTableItemsData, `${tableMode}${tableSubMode}`);
    } else {
      const newTableItems = tableItems.filter((item) =>
        Boolean(
          Object.keys(item).filter((key) => {
            if (typeof item[key] === "string") {
              return !isEmptyStringsFilterMode
                ? item[key].includes(action.payload)
                : action.payload !== ""
                  ? item[key].includes(action.payload) || item[key] === ""
                  : item[key] === "";
            }

            return false;
          }).length,
        ),
      );
      yield put(actions.setFilteredTableItemsStore(newTableItems));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* setIsEmptyStringsFilterMode() {
  yield put(actions.setIsEmptyStringsFilterModeStore());
  const searchValue = yield select(selectors.getStringsSearchInputValue);
  yield call(setStringsSearchInputValue, { payload: searchValue });
}
